import React, { useState, useEffect } from "react";
import SeatIcon from '../../assets/images/SeatIcon';
import ticketImgOne from '../../assets/images/ticketImgOne.jpg';
import ticketImgTwo from '../../assets/images/ticketImgTwo.jpg';
import ticketImgThree from '../../assets/images/ticketImgThree.jpg';
import { useParams } from 'react-router-dom';
import "./CryptoTickets.scss";
import calendarImg from '../../assets/images/calendar.svg';
import gridsImg from '../../assets/images/gridsImg.png';
import closeImg from '../../assets/images/closeImg.png';
import { useChain } from "../../chainContext";
import { Loader, Spinners } from '../../cpmponents';


const CryptoTickets = () => {

  const { id } = useParams()
  const { walletContract, rpcContract } = useChain()


  const ticketsData = [
    {
      id: 0,
      src: ticketImgTwo,
      title: 'SHEN YUN 2022',
      text: `New York-based Shen Yun returns to Australia as part of its global tour. Shen Yun's works convey the extraordinary depth and breadth of genuine Chinese culture and bring the magnificence of five thousand years of civilization back to life on stage`,
      date: `Fri, Apr 22`,
      name: `St Kilda, VICPalais Theatre`,
      time: `7:30 PM - 9:30 PM`,
      numberChair: ` seats available`
    },
    {
      id: 1,
      src: ticketImgThree,
      title: 'MARY POPPINS',
      text: `Disney and Cameron Mackintosh’s new production of MARY POPPINS will open at the Sydney Lyric Theatre in May 2022 for a strictly limited run.
      At its heart MARY POPPINS is a story about family and the infinite possibilities that lie within us all. It’s time to rediscover the undeniable magic of childhood ...`,
      date: `Thu, May 19`,
      name: `Pyrmont, NSW Sydney Lyric`,
      time: `7:00 PM - 9:00 PM`,
      numberChair: ` seats available`
    },
    {
      id: 2,
      src: ticketImgOne,
      title: 'CINDERELLA',
      text: `Rodgers & Hammerstein's CINDERELLA is the Tony Award® winning Broadway musical from the creators of The Sound of Music that delighted Broadway audiences with its surprisingly contemporary take on the classic tale.`,
      date: `Wed, Nov 9`,
      name: `Pyrmont, NSWSydney Lyric`,
      time: `1:30 PM - 3:30 PM`,
      numberChair: `Full`,
      isFull: true
    }
  ]



  //comments


  const [selected, setSelected] = useState([])
  const [boughtSeat, setBoughtSeat] = useState([])
  const [basePrice, setBasePrice] = useState(0)
  const [price, setPrice] = useState(0)
  const [buyHash, setbuyHash] = useState()
  const [seatInfo, setSeatInfo] = useState("")
  const [indexInfo, setIndexInfo] = useState(0)
  const [loading, setLoading] = useState(false)
  const details = ticketsData?.find((item) => item.id === parseInt(id))

  const getSeats = async () => {

    const seats = await rpcContract?.GetSeats(id)

    const data = (seats)?.toString()?.split(",")?.map((item) => parseInt(item))
    setBoughtSeat(data)

  }

  const getBasePrice = async () => {
    const data = await rpcContract?.GetBasePrice(id)
    data && setBasePrice(parseInt(data?.toString()))
  }

  const getSeatInfo = async () => {
    const data = await rpcContract?.GetSeatInfo(id, indexInfo)
    data && setSeatInfo(data.toString())
  }

  const handleBuy = async () => {

    const data = await walletContract?.Buy(id, selected.length, selected, { value: price })
    await getSeats()
    // setSelected([])
    if (data) { setbuyHash(data.hash); setLoading(false) }

  }


  function range(start, end) {
    return Array(end - start + 1).fill().map((_, idx) => start + idx)
  }

  const handleClick = (item) => {
    if (selected.includes(item)) {
      const filteredArray = selected.filter((ite) => ite !== item)

      setSelected(filteredArray)
    } else { setSelected(prev => [...prev, item]) }
  }


  const calculatePrice = (price) => {
    let firstRowCount = 0;
    let secondRowCount = 0
    let thirdRowCount = 0

    const transform = price.map((item) => {
      if (item <= 31 && item >= 0) return 1
      if (item <= 63 && item >= 32) return 1
      if (item <= 95 && item >= 64) return 2
      if (item <= 127 && item >= 96) return 2
      if (item <= 135 && item >= 128) return 3
      if (item <= 143 && item >= 136) return 3
    })
    transform.map((item) => (item === 1 && firstRowCount++))
    transform.map((item) => (item === 2 && secondRowCount++))
    transform.map((item) => (item === 3 && thirdRowCount++))


    return firstRowCount * basePrice + secondRowCount * basePrice * 2 + thirdRowCount * basePrice * 3

  }

  useEffect(() => {
    const sum = calculatePrice(selected)
    if (sum >= 0) setPrice(sum)
  }, [selected])


  useEffect(() => {
    getSeats()
    getBasePrice()

  }, [rpcContract, boughtSeat])


  useEffect(() => {
    getSeatInfo()
  }, [indexInfo])






  if (!boughtSeat || !basePrice || loading) {
    return <>
      <Loader />
      <Loader overlay spinnerBouncedelay />
    </>
  } else {
    return (
      <main className="main cryptoPage">
        <div className="container">
          <div className="cryptoWrapper">
            <div className="row">
              <div className="col-xl-9 col-lg-12 col-md-12 col-12">
                <div className="crypto">

                  {window.innerWidth < 1200 && <div className="ticketWrapper">
                    <img className="ticketImg" src={details.src} alt='' />
                    {window.innerWidth < 1200 && <h1 className="title">{details?.title}</h1>}
                    <div className="dateRapper">
                      <div>
                        <img src={calendarImg} alt='' />
                        <time className="date">
                          {details.date}
                        </time>
                      </div>
                      <label>
                        {details.name}
                        St Kilda, VICPalais Theatre
                      </label>
                      <time className="time">
                        {details.time}

                      </time>
                    </div>
                  </div>}

                  {window.innerWidth > 1199 && <h1 className="title">{details?.title}</h1>}
                  <h2 className="subTitle" >
                    {details?.text}
                  </h2>

                  <h6 className="titleSeats">Please select your seats</h6>
                  <div className="gridWrapper">
                    <div className="girdContainer">
                      {range(0, 31)?.map((item, index) => (<div onMouseLeave={() => { setSeatInfo("") }} onMouseEnter={() => boughtSeat?.includes(item) ? setIndexInfo(parseInt(item)) : 0} key={item + "j"} onClick={() => boughtSeat?.includes(item) ? "" : handleClick(item)} className={boughtSeat?.includes(item) ? "imageContainer " : "imageContainer pointer "} >


                        {boughtSeat?.includes(item) ? <div className="tooltip">{seatInfo ? seatInfo : <div style={{ height: "20px" }}><Loader /><Spinners /></div>}</div> : <></>}

                        <div className="centered">{item + 1} </div><SeatIcon fill={boughtSeat?.includes(item) ? "#DD1111" : selected.includes(item) ? "#47863C" : "#C8C8C8"} /></div>))}
                    </div>
                    <div className="girdContainer">
                      {range(32, 63)?.map((item, index) => (<div onMouseLeave={() => { setSeatInfo("") }} onMouseEnter={() => boughtSeat?.includes(item) ? setIndexInfo(parseInt(item)) : 0} key={item + "j"} onClick={() => boughtSeat?.includes(item) ? null : handleClick(item)} className={boughtSeat?.includes(item) ? "imageContainer " : "imageContainer pointer "} >


                        {boughtSeat?.includes(item) ? <div className="tooltip">{seatInfo ? seatInfo : <div style={{ height: "20px" }}><Loader /><Spinners /></div>}</div> : <></>}

                        <div className="centered">{item + 1} </div><SeatIcon fill={boughtSeat?.includes(item) ? "#DD1111" : selected.includes(item) ? "#47863C" : "#C8C8C8"} /></div>))}
                    </div>
                    <div className="girdContainer">
                      {range(64, 95)?.map((item, index) => (<div onMouseLeave={() => { setSeatInfo("") }} onMouseEnter={() => boughtSeat?.includes(item) ? setIndexInfo(parseInt(item)) : 0} key={item + "j"} onClick={() => boughtSeat?.includes(item) ? null : handleClick(item)} className={boughtSeat?.includes(item) ? "imageContainer " : "imageContainer pointer "} >


                        {boughtSeat?.includes(item) ? <div className="tooltip">{seatInfo ? seatInfo : <div style={{ height: "20px" }}><Loader /><Spinners /></div>}</div> : <></>}

                        <div className="centered">{item + 1} </div><SeatIcon fill={boughtSeat?.includes(item) ? "#DD1111" : selected.includes(item) ? "#47863C" : "#C8C8C8"} /></div>))}
                    </div>
                    <div className="girdContainer">
                      {range(96, 127)?.map((item, index) => (<div onMouseLeave={() => { setSeatInfo("") }} onMouseEnter={() => boughtSeat?.includes(item) ? setIndexInfo(parseInt(item)) : 0} key={item + "j"} onClick={() => boughtSeat?.includes(item) ? null : handleClick(item)} className={boughtSeat?.includes(item) ? "imageContainer " : "imageContainer pointer "} >


                        {boughtSeat?.includes(item) ? <div className="tooltip">{seatInfo ? seatInfo : <div style={{ height: "20px" }}><Loader /><Spinners /></div>}</div> : <></>}

                        <div className="centered">{item + 1} </div><SeatIcon fill={boughtSeat?.includes(item) ? "#DD1111" : selected.includes(item) ? "#47863C" : "#C8C8C8"} /></div>))}
                    </div>
                    <div className="girdContainer">
                      {range(128, 135)?.map((item, index) => (<div onMouseLeave={() => { setSeatInfo("") }} onMouseEnter={() => boughtSeat?.includes(item) ? setIndexInfo(parseInt(item)) : 0} key={item + "j"} onClick={() => boughtSeat?.includes(item) ? null : handleClick(item)} className={boughtSeat?.includes(item) ? "imageContainer " : "imageContainer pointer "} >


                        {boughtSeat?.includes(item) ? <div className="tooltip">{seatInfo ? seatInfo : <div style={{ height: "20px" }}><Loader /><Spinners /></div>}</div> : <></>}

                        <div className="centered">{item + 1} </div><SeatIcon fill={boughtSeat?.includes(item) ? "#DD1111" : selected.includes(item) ? "#47863C" : "#C8C8C8"} /></div>))}
                    </div>
                    <div className="girdContainer">
                      {range(136, 143)?.map((item, index) => (<div onMouseLeave={() => { setSeatInfo("") }} onMouseEnter={() => boughtSeat?.includes(item) ? setIndexInfo(parseInt(item)) : 0} key={item + "j"} onClick={() => boughtSeat?.includes(item) ? null : handleClick(item)} className={boughtSeat?.includes(item) ? "imageContainer " : "imageContainer pointer "} >


                        {boughtSeat?.includes(item) ? <div className="tooltip">{seatInfo ? seatInfo : <div style={{ height: "20px" }}><Loader /><Spinners /></div>}</div> : <></>}

                        <div className="centered">{item + 1} </div><SeatIcon fill={boughtSeat?.includes(item) ? "#DD1111" : selected.includes(item) ? "#47863C" : "#C8C8C8"} /></div>))}
                    </div>



                  </div>
                  <img className="gridsImg" src={gridsImg} alt='' />
                  {buyHash && !loading ? <div className="alertWrapper">
                    <a href={`https://rinkeby.etherscan.io/tx/${buyHash}`} target="_blank" rel="noreferrer"  >
                      Your seats has been reserved successfully. See your wallet for details.  <img src={closeImg} alt='' />
                    </a>
                  </div> : <></>}


                </div>
              </div>

              <div className="col-xl-3 col-lg-12 col-md-12 col-12">
                <div className="colLeft">
                  {window.innerWidth > 1199 && <div className="ticketWrapper">
                    <img className="ticketImg" src={details.src} alt='' />
                    <div className="dateRapper">
                      <div>
                        <img src={calendarImg} alt='' />
                        <time className="date">
                          {details.date}
                        </time>
                      </div>
                      <label>
                        {details.name}
                        St Kilda, VICPalais Theatre
                      </label>
                      <time className="time">
                        {details.time}

                      </time>
                    </div>
                  </div>}

                  <div className="selectedSeatsWrapper" >
                    <h6>Selected seats : </h6>
                    <span>{selected?.map((item) => `${item + 1} `)?.toString()}</span>
                    <span className="price">Price : </span>
                    <span>{price}</span>
                    <button style={walletContract ? {} : { backgroundColor: "#bbbbbb", color: "white" }} className={walletContract ? "buttonBuy " : "buttonBuy disableBuy"}
                      disabled={!walletContract}
                      onClick={handleBuy}
                    >
                      BUY
                    </button>
                    {/* <button
                      onClick={handleBuy}
                    >
                      BUY
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </main>
    );


  }


}

export default CryptoTickets;
