
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './App.scss';
import HomePage from './pages/home/HomePage'
import CryptoTickets from './pages/CryptoTickets/CryptoTickets'
import Header from "./cpmponents/common/header/Header";
import Footer from "./cpmponents/common/footer/Footer";
import { ChainProvider } from './chainContext'

const App = () => {



  return (
    <ChainProvider>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/cryptotickets/:id" element={<CryptoTickets />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </ChainProvider>

  );
}

export default App;
