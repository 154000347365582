import React from "react";
import { NavLink } from "react-router-dom";
import calendarImg from '../../assets/images/calendar.svg';
import './CardTickets.scss';
import { Loader, Spinners } from '../../cpmponents';



const CardTickets = ({ data, isFull }) => {
  return (


    <NavLink style={isFull ? { cursor: "default" } : { cursor: "pointer" }} to={isFull ? "/" : `/cryptotickets/${data.id}`} className={`cardTickets ${isFull ? 'isFull' : ''}`}>
      <img className="imgTicket" src={data.src} alt='' />
      <div className="titleWrapper">
        <h2>
          {data.title}
        </h2>
        <p>
          {data.text}
        </p>
        <span>
          Read more ...
        </span>
      </div>
      <div className="colLeft">
        <div className="dateTimeWrapper">
          <img src={calendarImg} alt='' />
          <div className="dateWrapper">
            <time className="date">
              {
                data.date
              }
            </time>
            <h5>
              {
                data.name
              }
            </h5>
            <time className="time">
              {
                data.time
              }
            </time>

          </div>
        </div>

        <div className="chairWrapper">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 24C3.52614 24 3 23.0051 3 22.0262C3.75 20.5577 4.5 19.8235 4.5 16.8867L4.50002 5.87365L19.5 5.87364V16.8867C19.5 19.0893 21 19.8235 21 22.0262C21 23.4946 18.8311 24 17.25 24H6Z" fill="#0067A1" />
            <path d="M24 6.60785H22.5V19.8235H24V6.60785Z" fill="#0067A1" />
            <path d="M1.5 19.8235H0V6.60785H1.5V19.8235Z" fill="#0067A1" />
            <path d="M12 2.20262C8.4 2.20262 4.5 3.18156 3 3.67103V1.46841C4.75 0.97894 9 0 12 0C15 0 19.25 0.97894 21 1.46841V3.67103C19.5 3.18156 15.6 2.20262 12 2.20262Z" fill="#0067A1" />
          </svg>

          <span>
            {



              data.isFull ? "Full" :
                data.numberChair ? data.numberChair + "seats available " :
                  <>
                    <div style={{ marginBottom: "20px" }}>
                      <Loader />

                      <Spinners />
                    </div>
                  </>
            }
          </span>
          <button style={isFull ? { cursor: "default" } : { cursor: "pointer" }}>
            Buy now
          </button>
        </div>
      </div>
    </NavLink >


  );
}

export default CardTickets;
