

import React from "react";
import { NavLink } from "react-router-dom";
import calendarImg from '../../assets/images/calendar.svg';
import './FavoriteSeat.scss';

const FavoriteSeat = ({ data, isFull }) => {
  return (
    <div className={`favoriteSeat`}>
      <div className="colLeft">
        <span>
          Book
        </span>
        <h4>
          Theater tickets
        </h4>
        <label>
          with cryptos
        </label>
      </div>
      <div className="colRight">
        <span>
          Choose your
        </span>
        <h4>
          favorite seat
        </h4>
        <label>
          in the hall
        </label>
      </div>
    </div>
  );
}

export default FavoriteSeat;
