
import React from 'react';


import { useChain } from '../../chainContext'

const Web3ReactConnectionComponent = () => {


	const { walletAddress, flag, setFlag } = useChain()


	return (
		<>
			<div className='connectionWrapper'>
				<div className='message'>{walletAddress ? <p>{`${walletAddress?.substring(0, 7)}...${walletAddress?.substring(35)}`}</p> : <p>Not connected</p>}</div>
				<button className="buttonBuy" onClick={() => setFlag(!flag)} alt="" >Connect Wallet</button>
			</div>
		</>
	);
};

export default Web3ReactConnectionComponent;
