import React from "react";
import './Footer.scss';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footerContent">
          <label>
            Crypto tickets
          </label>
          {/* <span>
            Copyright Raadco PTY LTD
          </span> */}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
