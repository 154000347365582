import React from 'react';
import './Loader.scss';

export const Loader = ({
    spinnerBounce,
    spinnerBouncedelay,
    overlay,
    width,
    height,
    loadingDot }) => {

    return (
        <>
            {!loadingDot && !spinnerBouncedelay && !spinnerBounce && <div className={`loader`} style={{ width: `${width}`, height: `${height}` }} />}

            {!overlay && spinnerBounce &&
                <div className={`ldsDualRing`}></div>
            }

            {!overlay && spinnerBouncedelay && <div className={`spinnerBouncedelay`} >
                <div className={`bounce1`}></div>
                <div className={`bounce2`}></div>
                <div className={`bounce3`}></div>
            </div>}

            {
                overlay && spinnerBouncedelay && <div className={`overlayLoading`}>
                    <div className={`spinnerBouncedelay`}>
                        <div className={`bounce1`}></div>
                        <div className={`bounce2`}></div>
                        <div className={`bounce3`}></div>
                    </div>
                </div>
            }

            {loadingDot && <p className="loading">Loading</p>}
        </>
    );
};




