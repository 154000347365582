

import React, { useState, useEffect } from "react";
import CardTickets from '../../cpmponents/cardTickets/CardTickets';
import FavoriteSeat from '../../cpmponents/favoriteSeat/FavoriteSeat';
import ticketImgOne from '../../assets/images/ticketImgOne.jpg';
import ticketImgTwo from '../../assets/images/ticketImgTwo.jpg';
import ticketImgThree from '../../assets/images/ticketImgThree.jpg';
import './HomePage.scss'
import { ethers } from "ethers";
import { contractABI, contractAddress } from "../../Helpers/contract";






const HomePage = () => {

  const [fstatus, setfstatus] = useState(false)
  const [fcount, setfcount] = useState(0)
  const [scount, setscount] = useState(0)
  const [tcount, settcount] = useState(0)



  const getRemain = async () => {

    const provider = new ethers.providers.JsonRpcProvider(
      "https://rinkeby.infura.io/v3/39d96f8425dc4152b60b6bb37f759154"
    );
    const contract = new ethers.Contract(
      contractAddress,
      contractABI,
      provider
    );

    const firstCount = await contract?.remain(0)
    const secondCount = await contract?.remain(1)
    const thirdCount = await contract?.remain(2)
    setfcount(firstCount?.toString())
    setscount(secondCount?.toString())
    settcount(thirdCount?.toString())
  };



  const ticketsData = [
    {
      id: 0,
      src: ticketImgTwo,
      title: 'SHEN YUN 2022',
      text: `New York-based Shen Yun returns to Australia as part of its global tour. Shen Yun's works convey the extraordinary depth and breadth of genuine Chinese culture and bring the magnificence of five thousand years of civilization back to life on stage`,
      date: `Fri, Apr 22`,
      name: `St Kilda, VICPalais Theatre`,
      time: `7:30 PM - 9:30 PM`,
      numberChair: fcount
    },
    {
      id: 1,
      src: ticketImgThree,
      title: 'MARY POPPINS',
      text: `Disney and Cameron Mackintosh’s new production of MARY POPPINS will open at the Sydney Lyric Theatre in May 2022 for a strictly limited run.
      At its heart MARY POPPINS is a story about family and the infinite possibilities that lie within us all. It’s time to rediscover the undeniable magic of childhood ...`,
      date: `Thu, May 19`,
      name: `Pyrmont, NSW Sydney Lyric`,
      time: `7:00 PM - 9:00 PM`,
      numberChair: scount
    },
    {
      id: 2,
      src: ticketImgOne,
      title: 'CINDERELLA',
      text: `Rodgers & Hammerstein's CINDERELLA is the Tony Award® winning Broadway musical from the creators of The Sound of Music that delighted Broadway audiences with its surprisingly contemporary take on the classic tale.`,
      date: `Wed, Nov 9`,
      name: `Pyrmont, NSWSydney Lyric`,
      time: `1:30 PM - 3:30 PM`,
      numberChair: `Full`,
      isFull: true
    }
  ]

  useEffect(() => {
    getRemain()
  }, [])

  return (
    <main className="main homePage">

      <div className="container">
        <FavoriteSeat />
        <div className="cardTicketsWrapper">
          {
            ticketsData?.map((item, index) => (
              <CardTickets
                isFull={item.isFull}
                key={index}
                data={item}
              />
            ))}
        </div>
      </div>
    </main>
  );
}

export default HomePage;
