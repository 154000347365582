import React from "react";
import headerImg from '../../../assets/images/headerImg.jpg';
import './Header.scss';
import Web3ReactConnectionComponent from '../../../pages/CryptoTickets/Web3ReactConnectionComponent'
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <>
      <header className="header">
        <div className="container-fluid">
          <div className="titleWrapper">
            <div className="headerContent">
              <h1 className="title">
                <Link to={`/`}>
                  Crypto tickets
                </Link>
              </h1>

              <Web3ReactConnectionComponent />
            </div>
          </div>
        </div>

        <img src={headerImg} alt='' />
      </header>
    </>
  );
}

export default Header;
