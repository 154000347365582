import React from 'react';
import './Loader.scss';

export const Spinners = ({
    spinners,
    ldsRing }) => {
    return (
        <>
            {spinners && <div className="rhSpinners"></div>}
            {ldsRing && <div className="lds-ring"><div></div><div></div><div></div><div></div></div>}
        </>
    );
};

