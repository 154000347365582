
import React, { useContext, useEffect, useState, } from 'react'
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import { ethers } from "ethers";

import { contractABI, contractAddress } from '../Helpers/contract'




const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: "ebf2aa9568884ba0aceb39bc35377102", // required
    },
  },
};
const web3Modal = new Web3Modal({
  network: "mainnet", // optional
  cacheProvider: false, // optional
  disableInjectedProvider: false,
  providerOptions, // required
});


const ChainContext = React.createContext()

export function useChain() {
  return useContext(ChainContext)
}

export function ChainProvider({ children }) {

  const [flag, setFlag] = useState(false)

  const [walletContract, setWalletContract] = useState(null)

  const [walletProvider, setWalletProvider] = useState(null)

  const [rpcProvider, setRpcProvider] = useState(null)

  const [rpcContract, setrpcContract] = useState(null)

  const [walletAddress, setWalletAddress] = useState("")


  const getWalletProvider = async () => {


    await web3Modal.clearCachedProvider();
    const instance = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(instance);
    const signer = provider?.getSigner();
    const signerAddress = await signer?.getAddress();
    const contract = new ethers.Contract(
      contractAddress,
      contractABI,
      signer
    );
    setWalletAddress(signerAddress)
    setWalletContract(contract)
    setWalletProvider(provider)

  };

  const RpcContract = async () => {
    // await web3Modal.clearCachedProvider();
    const provider = new ethers.providers.JsonRpcProvider(
      "https://rinkeby.infura.io/v3/39d96f8425dc4152b60b6bb37f759154"
    );
    const rpcContract = new ethers.Contract(
      contractAddress,
      contractABI,
      provider
    );

    setrpcContract(rpcContract)
    setRpcProvider(provider)
  };

  useEffect(() => {
    RpcContract()
  }, [])

  useEffect(() => {
    getWalletProvider()
  }, [flag])



  return (
    <ChainContext.Provider value={{ walletAddress, walletContract, rpcContract, walletProvider, rpcProvider, flag, setFlag }}>
      {children}
    </ChainContext.Provider>
  )
}